import React, { useMemo } from "react"
import FeaturedBlog from "../../components/FeaturedBlog/FeaturedBlog"
import BlogHeader from "../../components/BlogHeader/BlogHeader"
import Author from "../../components/Author/Author"
import Share from "../../components/Share/Share"
import ZigZag from "../../components/ZigZag/ZigZag"
import Contact from "../../components/Contact/Contact"
import { Layout } from "../../layouts"
import readingTime from "reading-time"
import "./GeneralDetail.scss"
import { useZigzagImageSides } from "../../hooks/useZigzagImageSides"
import { graphql } from "gatsby"
import PostBody from "../../components/PostBody/PostBody"
import SEO from "../../components/Seo"
import { localizeDataNodes } from "../../utils/localizeDataNodes"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import SubMenu from "../../components/SubMenu/SubMenu"
import { MENUS_IDS } from "../../constants"

const ZIGZAG_IMAGE_SIDE_START = "left"

const GeneralDetailTemplate = ({
  pageContext: { generalPost, routePrefix = "", lang, companies },
  data,
  location,
}) => {
  const {
    allDzemoApiCompanies,
    allDzemoApiZigzags,
    allDzemoApiPages,
    allDzemoApiGenerals,
  } = useMemo(() => localizeDataNodes(data, lang), [data, lang])
  const zigzagImageSides = useZigzagImageSides(
    allDzemoApiZigzags,
    ZIGZAG_IMAGE_SIDE_START
  )
  const blogTitleCapitalized = capitalizeFirstLetter(generalPost[lang].name)

  const recruitingSubPagesGenerals =
    allDzemoApiGenerals
      .filter(({ menu }) => menu?.id === MENUS_IDS.recruitingMenuId)
      .sort((a, b) => (a.weight < b.weight ? -1 : 1)) ?? []

  const recruitingSubPagesPages =
    allDzemoApiPages
      .filter(({ menu }) => menu?.id === MENUS_IDS.recruitingMenuId)
      .sort((a, b) => (a.weight < b.weight ? -1 : 1)) ?? []

  const allSubPages = recruitingSubPagesGenerals
    .concat(recruitingSubPagesPages)
    .sort((a, b) => (a.weight < b.weight ? -1 : 1))

  return (
    <Layout
      location={location}
      lang={lang}
      pageData={generalPost}
      routePrefix={routePrefix}
      companies={companies}
    >
      <SEO
        title={blogTitleCapitalized}
        canonical={location.pathname}
        data={generalPost[lang]}
      />
      <SubMenu data={allSubPages} location={location} />
      <div className="general-detail-page">
        <FeaturedBlog data={generalPost[lang]} />
        <BlogHeader
          author={generalPost[lang].profile}
          company={allDzemoApiCompanies}
          createdAt={generalPost[lang].created_at}
          readingTime={readingTime(generalPost[lang].body)}
        />
        <PostBody data={generalPost[lang]} className="general-post-body" />
        <Share data={allDzemoApiCompanies[0]} />
        {generalPost[lang].profile && (
          <Author data={generalPost[lang].profile} />
        )}
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "250px",
          }}
        >
          {generalPost[lang].zigzags.map((zigzag, index) => {
            const { id } = zigzag

            return (
              <ZigZag
                key={id}
                data={{
                  ...zigzag,
                  image: zigzag.image[0],
                  sideOfImage: zigzagImageSides[index],
                }}
              />
            )
          })}
        </section>
        <section
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: "250px",
            background: "black",
          }}
        >
          <Contact
            mode="black"
            message={{ message: "Your message has been sent!", type: "error" }}
          />
        </section>
      </div>
    </Layout>
  )
}
export default GeneralDetailTemplate

export const query = graphql`
  query generalDetailQuery($generalId: Int!) {
    allDzemoApiCompanies(limit: 1) {
      nodes {
        en {
          facebook
          instagram
          twitter
        }
        de {
          facebook
          instagram
          twitter
        }
      }
    }

    allDzemoApiZigzags(
      filter: { en: { generals: { elemMatch: { id: { eq: $generalId } } } } }
      sort: { fields: en___weight, order: ASC }
    ) {
      nodes {
        en {
          id
          body
          name
          image {
            formats {
              normal {
                url
                width
                size
                name
                height
              }
              normal_sm {
                url
                width
                size
                name
                height
              }
            }
            alternativeText
          }
          route
          id
        }
        de {
          id
          body
          name
          image {
            formats {
              normal {
                url
                width
                size
                name
                height
              }
              normal_sm {
                url
                width
                size
                name
                height
              }
            }
            alternativeText
          }
          route
          id
        }
      }
    }

    allDzemoApiPages(sort: { order: ASC, fields: en___weight }) {
      nodes {
        en {
          id
          name
          template
          path
          metadescription
          weight
          subpages {
            id
            name
            path
            weight
          }
          menu {
            id
          }
          icon {
            url
            width
            height
            hash
            alternativeText
          }
        }
        de {
          id
          name
          template
          path
          metadescription
          weight
          subpages {
            id
            name_de
            path_de
            weight
          }
          menu {
            id
          }
          icon {
            url
            width
            height
            hash
            alternativeText
          }
        }
      }
    }

    allDzemoApiGenerals(sort: { fields: en___created_at, order: DESC }) {
      nodes {
        en {
          id
          name
          menu {
            id
          }
          route
          weight
          created_at(formatString: "MMM d, yyyy")
          image {
            formats {
              blog {
                url
                name
                hash
                width
                height
              }
              blog_lg {
                hash
                size
                url
                width
                name
              }
              blog_sm {
                height
                hash
                name
                url
                width
              }
            }
            alternativeText
          }
          abstract
          body
          teaserText
          profile {
            name
            name_de
            linkedin
            position
            shortBio
            shortBio_de
            email
            image {
              formats {
                team_mini {
                  url
                  width
                  name
                  height
                  hash
                }
                team_small {
                  url
                  width
                  name
                  height
                  hash
                }
              }
              alternativeText
            }
          }
          metadescription
          icon {
            url
            width
            height
            hash
            alternativeText
          }
        }
        de {
          id
          name
          menu {
            id
          }
          route
          weight
          created_at(formatString: "MMM d, yyyy")
          image {
            formats {
              blog {
                url
                name
                hash
                width
                height
              }
              blog_lg {
                hash
                size
                url
                width
                name
              }
              blog_sm {
                height
                hash
                name
                url
                width
              }
            }
            alternativeText
          }
          abstract
          body
          teaserText
          profile {
            name
            name_de
            linkedin
            position
            shortBio
            shortBio_de
            email
            image {
              formats {
                team_mini {
                  url
                  width
                  name
                  height
                  hash
                }
                team_small {
                  url
                  width
                  name
                  height
                  hash
                }
              }
              alternativeText
            }
          }
          metadescription
          icon {
            url
            width
            height
            hash
            alternativeText
          }
        }
      }
    }
  }
`
